<template>
  <!-- 资源列表 -->
  <div class='ResourceManage'>
    <div class="navC">
      <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;">
        <el-tab-pane label="研修资源管理" name="1"></el-tab-pane>
        <el-tab-pane label="资源文件夹管理" name="2"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="contentBox">
      <div class="selectBox">
        <div class="xj">
          <span>+</span>
          <span @click="dialogTable = true">新建文件夹</span>
        </div>
        <div class="inputBox">
          <el-input v-model="form.search_name" clearable placeholder="请输入关键词搜索"></el-input>
          <el-button type="primary" @click="initFile()" style="margin-left:20px">搜索</el-button>
        </div>
      </div>
      <p class="AllBx">全部文件</p>
      <div class="ListBox" v-if="fileData.length > 0">
        <div class="forBox" v-for="(item, index) in fileData" :key="index">
          <!-- //左边 -->
          <div class="leftB">
            <div class="photo">
              <!-- <img src="" alt=""> -->
              <i class="iconfont iconMS_ziyuanliebiao01"></i>
            </div>
            <div class="txtBox">
              <p>{{ item.name }}</p>
            </div>
          </div>
          <!-- //右边 -->
          <div class="rightBox">
            <span @click="ToDelShow(item)">重命名</span>
            <span @click="delData(item)">删除</span>
          </div>
        </div>
        <div class="pagination-box" v-show="total">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
          </el-pagination>
        </div>
      </div>
      <CommonEmpty v-else />
    </div>
    <el-dialog title="新建文件夹" :visible.sync="dialogTable" style="width:800px;margin:0 auto">
      <el-input v-model="form1.name" placeholder="请输入内容"></el-input>
      <div style="margin-top:20px;margin-left:100px">
        <el-button type="primary" @click="AddFile()" v-if="type == 1">保存</el-button>
        <el-button type="primary" @click="setName()" v-if="type == 2">保存</el-button>
        <el-button @click="cancel()">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1, //文件夹类型编辑还是添加
      dialogTable: false,
      input21: '',
      isShow: -1,
      fileData: [],
      //文件夹表单数据
      form: {
        page: 1,
        limit: 10,
        search_name: '',
        teaching_studio_id: localStorage.getItem('studioId'),
      },
      total: 0,
      form1: {
        id: undefined,
        name: '',
        teaching_studio_id: localStorage.getItem('studioId'),

      },
      activeName: '2',
    }
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    this.init()
  },
  computed: {
  },
  methods: {
    //每页条数回调
    handleSizeChange(val) {
      this.form.limit = val;
      this.initFile();
    },
    //当前页回调
    handleCurrentChange(val) {
      this.form.page = val;
      this.initFile();
    },
    handleClick() {
      this.$router.push({
        path: "/ResourceList"
      })
    },
    init() {
      this.initFile();
    },
    //初始化文件夹数据
    initFile() {
      this.$axios.get('TeachingStudioUserResourceType/list', {
        params: this.form
      }).then((res) => {
        this.fileData = res.data.data.data;
        this.total = res.data.data.total
      })
    },

    //新建
    AddFile() {
      this.$axios.post('TeachingStudioUserResourceType/add', this.form1).then(() => {
        this.dialogTable = false;
        this.$message.success("新建文件夹成功")
        this.initFile();
      })
    },

    //取消新建
    cancel() {
      this.form1.name = "";
      this.dialogTable = false;
    },

    //删除文件夹
    delData(item) {
      this.$axios.delete('TeachingStudioUserResourceType/delTeachingStudioUserResourceType',
        {
          params: {
            id: item.id
          }
        }
      ).then(() => {
        this.dialogTable = false;
        this.$message.success("删除文件夹成功")
        this.initFile();
      })
    },

    //显示编辑框
    ToDelShow(item) {
      this.form1.name = item.name;
      this.form1.id = item.id;
      this.dialogTable = true;
      this.type = 2
    },

    // 重命名
    setName() {
      this.$axios.put('TeachingStudioUserResourceType/edit', this.form1).then(() => {
        this.form1.name = '';
        this.form1.id = undefined;
        this.dialogTable = false;
        this.$message.success("重命名文件夹成功")
        this.initFile();
      })
    }

  },
}
</script>
<style lang="less" scoped>
.ResourceManage {
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: end;
    border-bottom: 1px solid #f0f0f0;
    justify-content: space-between;
    box-sizing: border-box;

    /deep/.el-tabs__header {
      margin: 0;

      .el-tabs__item {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .el-tabs__item.is-active {
        font-weight: bold;
      }
    }
  }

  .contentBox {
    padding: 0 26px;

    .selectBox {
      display: flex;
      justify-content: space-between;
      margin-top: 26px;

      .xj {
        width: 128px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #3489ff;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #3489ff;
      }

      .inputBox {
        display: flex;
        align-items: center;
      }
    }

    .AllBx {
      width: 64px;
      font-size: 16px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      color: #999999;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .ListBox {
      .forBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #f0f0f0;

        .leftB {
          display: flex;
          align-items: center;

          .photo {
            width: 22px;
            height: 18px;

            i {
              font-size: 22px;
              color: #409eff;
            }
          }

          .txtBox {
            display: flex;
            align-items: center;

            p {
              width: 272px;
              font-size: 16px;
              font-family: Microsoft YaHei Regular,
                Microsoft YaHei Regular-Regular;
              font-weight: 400;
              color: #2b2b2b;
              margin-left: 15px;
            }
          }
        }

        .rightBox {
          color: #3489fe;
          cursor: pointer;

          span {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.pagination-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
